<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <v-btn
        icon
        class="mr-2"
        @click="
          $router.push(
            isStudent
              ? { name: 'EtestHistory' }
              : {
                  name: 'ValueList',
                  query: { id: $route.query.etest },
                }
          )
        "
      >
        <v-icon large>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="font-weight-bold">Daftar Nilai E-Test</span>
    </v-col>
    <v-col cols="12">
      <Etest readonly correction />
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    Etest: () => import("./components/Etest.vue"),
  },
  computed: {
    isStudent() {
      return this.$store.getters.g_roles === "STUDENT";
    },
  },
};
</script>
