var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push(
          _vm.isStudent
            ? { name: 'EtestHistory' }
            : {
                name: 'ValueList',
                query: { id: _vm.$route.query.etest },
              }
        )}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-arrow-left")])],1),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Daftar Nilai E-Test")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Etest',{attrs:{"readonly":"","correction":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }